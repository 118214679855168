export const API_JOBS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job'
export const API_SCHEDULED_JOBS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job_scheduled'
export const API_JOBS_ROLLUP_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/rollup'
export const API_JOB_CREATE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/prospect_by_domain_advanced'
export const API_JOB_LEAD_SEARCH_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/prospect_by_lead_search'
export const API_FIND_EMAIL_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/find_email'
export const API_VERIFY_EMAIL_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/verify_email'
export const API_CRM_VERIFY_EMAIL_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/crm_verify_email'
export const API_VERIFY_EMAIL_CSV_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/verify_email_csv'
export const API_FIND_EMAIL_CSV_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/find_email_csv'
export const API_CRM_VERIFY_EMAIL_TEST_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'job/crm_verify_email'
export const API_BOTS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'bot'
export const API_BOT_OPTIONS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'bot/options'
export const API_BOT_PAUSE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'bot/pause'
export const API_PROXIES_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'proxy/available'
export const API_PLAYBOOK_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'playbook'
export const API_USER_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'user'
export const API_USER_SETTINGS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'user_settings'
export const API_PAYMENT_METHOD_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'payment_method'
export const API_AUTO_RECHARGE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'payment/auto_recharge'
export const API_INVOICES_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'invoice'
export const API_INVOICE_DOWNLOAD_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'invoice/download'
export const API_CREDIT_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'credit'
export const API_SUBSCRIPTION_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'subscription'
export const API_APIKEY_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'api_key'
export const API_LPDEFENDER_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'js/form_blocker_tag'
export const API_INTEGRATIONS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'integrations'
export const API_LOGIN_GET_LINK_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'login_get_link'
export const API_LOGOUT_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'logout'
export const API_ACTIVITY_REQUESTSPERDAY_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/requests_by_day'
export const API_ACTIVITY_FINDEMAILSUCCESSPERCENTAGE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/find_email_success_percentage'
export const API_ACTIVITY_VERIFYEMAILOUTCOMES_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/verify_email_outcomes'
export const API_ACTIVITY_JOBCOST_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/job_cost'
export const API_ACTIVITY_EXECUTIONTIME_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/execution_time'
export const API_ACTIVITY_QUEUEDTIME_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/reports/queue_time'
export const API_PAYMENT_NOTIFICATIONSETTINGS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/payment/notification_settings'
export const API_SETUP_INTENT_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'billing/setup_intent'
export const API_3DS_CONFIRM_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'billing/3ds_confirm'
export const API_REFERRAL_CODE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + '/referral_code'
export const API_PROMO_CODE_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'promo_code'
export const API_INVOICE_SETTINGS_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT + 'billing/invoice_settings'